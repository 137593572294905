<template>
  <div class="echartItemMulti" v-if="isShow">
    <div class="echartTitle">运行时间</div>
    <div id="multiRunTime" class="echartBox"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      isShow: false,
      max: ""
    };
  },

  watch: {
    data: {
      handler(value) {
        const result = value.some(item => item.value !== 0);
        if (value.length > 0 && result) {
          this.isShow = true;
          this.max = Math.max(...value.map(item => item.value));
          this.init();
        } else {
          this.isShow = false;
        }
      }
    }
  },

  methods: {
    init() {
      this.$nextTick(() => {
        const myChart = this.$echarts.init(document.getElementById("multiRunTime"));
        const option = {
          title: {
            subtext: "单位：小时",
            left: 7
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            },
            formatter: "{b}<br />{c}小时"
          },
          grid: {
            left: "30",
            right: "24",
            bottom: "0%",
            top: "15%",
            containLabel: true
          },
          xAxis: {
            type: "category",
            triggerEvent: true,
            data: this.data.map(item => item.id),
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              formatter: (value) => {
                const item = this.data.find(item => item.id == value);
                if (item) {
                  return item.name.slice(0, 8) + "...";
                } else {
                  return "";
                }
              }
            },
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          },
          series: [
            {
              type: "bar",
              barWidth: 10,
              data: this.data.map(item => ({
                id: item.id,
                value: item.value,
                name: item.name,
                itemStyle: {
                  color: item.value == this.max ? new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#0747FD" },
                    { offset: 1, color: "#B4C7FF" }
                  ]) : "#B4C7FF"
                }
              }))
            }
          ]
        };
        myChart.setOption(option);

        myChart.on("click", (params) => {
          if (params.componentType === "xAxis") {
            const fullPath = this.$router.resolve({ name: "singleElevatorReport", params: { id: params.value } }).href;
            window.open(fullPath, "_blank");
          } else if (params.componentType === "series") {
            const fullPath = this.$router.resolve({ name: "singleElevatorReport", params: { id: params.data.id } }).href;
            window.open(fullPath, "_blank");
          }
        });
      });
    }
  }
};
</script>

<style lang="scss">
.echartItemMulti {
  margin-top: 26px;
  padding-left: 24px;

  .echartTitle {
    border-left: 4px solid #0747FD;
    padding-left: 8px;
    color: #4d4d4d;
  }

  .echartBox {
    width: 100%;
    height: 280px;
  }
}
</style>