<template>
  <div style="padding: 0 24px;border-radius: 12px;" v-if="isShow">
    <div id="multiFaultRank" class="echartBox"></div>
  </div>
</template>

<script>
export default {
  props: {
    faultType: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      isShow: false,
    };
  },

  watch: {
    faultType: {
      handler(value) {
        if (value.length > 0) {
          this.init();
          this.isShow = true;
        } else {
          this.isShow = false;
        }
      }
    }
  },

  methods: {
    init() {
      this.$nextTick(() => {
        const myChart= this.$echarts.init(document.getElementById("multiFaultRank"));
        const option = {
          backgroundColor: "#F5F8FF",
          title: {
            text: "故障类型Top5",
            left: "24",
            top: "30",
            textStyle: {
              color: "#0747FD"
            }
          },
          tooltip: {
            trigger: "item"
          },
          legend: {
            orient: "vertical",
            left: "24",
            top: "center",
          },
          color: [
            "#0747FD",
            "#7194FC",
            "#84D6FF",
            "#00D09C",
            "#FDBD50",
            "#FF7E48"
          ],
          series: [
            {
              type: "pie",
              radius: ["40%", "60%"],
              label: {
                formatter: "{c}次 ({d}%)"
              },
              data: this.faultType
            }
          ]
        };
        myChart.setOption(option);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.echartBox {
  width: 100%;
  height: 280px;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 13px;
}
</style>